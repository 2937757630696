window.addEventListener('DOMContentLoaded', function () {
  if( document.querySelector('.nav-toggle') ) {
    document
      .querySelector('.nav-toggle')
      .addEventListener('click', function (e) {
        var target = e.currentTarget.attributes["data-target"].value;
        document.querySelector(target).parentElement.classList.toggle("open");
        this.classList.toggle('active');
        document.body.classList.toggle('nav-toggle-'+target.replace('#','').replace('.',''));
      }, false)

    var navLink = document.querySelectorAll('.primary li')
    for (var i = 0; i < navLink.length; i++) {
      navLink[i].addEventListener('click', function(event) {
        if( document.body.clientWidth < 750 ) {
          if( event.target.parentElement.querySelector('nav') ) event.preventDefault()
        }
      })
    }
    if( document.querySelector('.navbar-toggle') ) {
      document
        .querySelector('.navbar-toggle')
        .addEventListener('click', function (e) {
          document.querySelector(e.currentTarget.attributes["data-target"].value).parentElement.classList.toggle("open");
          this
            .classList
            .toggle('active')
        }, false)

      var navLink = Array.from(document.querySelectorAll("nav-magellan li")).concat(Array.from(document.querySelectorAll("nav-microsite li")))
      for (var i = 0; i < navLink.length; i++) {
        navLink[i].addEventListener('click', function(event) {
          if( document.body.clientWidth < 750 ) {
            if( event.target.parentElement.querySelector('nav') ) event.preventDefault()
          }
        })
      }
    }
  }
}, false)
